import React, {useState, useEffect} from 'react'


export const Modaldetail = () => {
   return (
    <>
    <div className="modal fade modal-fullscreen" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                <div className="modal-header alert alert-primary">
                    <h5 className="modal-title" id="exampleModalLabel">Details zum Auftrag</h5> <a href="#notes" className="ms-5 btn btn-primary">Notizen lesen</a>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    
<h5>Fortschritt - Überblick</h5>
<div className='w-100 shadow-lg' id="progress">
<h6>Vlies</h6>
<div className="progress">
  <div className="progress-bar" role="progressbar" style={{width: '25%', ariaValuenow:"25",ariaValuemin:"0", ariaValuemax:"100"}}></div>
</div>
<br />
<h6>Hülle</h6>
<div className="progress">
  <div className="progress-bar" role="progressbar" style={{width: '50%', ariaValuenow:"25",ariaValuemin:"0", ariaValuemax:"100"}}></div>
</div></div>
<br /><hr /><br /><h5>Vollständiger Überblick</h5>
<table className='table table-striped'>
  <tbody className="table-striped">
    <tr>
        <td>Id</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Versand</td>
        <td>5/4/23</td>
    </tr>
    <tr>
        <td>Tagesprio</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Versandart</td>
        <td>F</td>
    </tr>
    <tr>
        <td>Einbaudatum </td>
        <td>5/23/23</td>
    </tr>
    <tr>
        <td>Kunde</td>
        <td>Bröcking </td>
    </tr>
    <tr>
        <td>Tankform</td>
        <td>Zylinder </td>
    </tr>
    <tr>
        <td>Kunststoff </td>
        <td>Mipoplast</td>
    </tr>
    <tr>
        <td>Volumen</td>
        <td>20000</td>
    </tr>
    <tr>
        <td>Vlies </td>
        <td>LSV 6 vernäht</td>
    </tr>
    <tr>
        <td>Notizen</td>
        <td></td>
    </tr>
    <tr>
        <td>Huellennr.</td>
        <td>12345</td>
    </tr>
    <tr>
        <td>MA</td>
        <td></td>
    </tr>
    <tr>
        <td>Zuschnitt</td>
        <td></td>
    </tr>
    <tr>
        <td>gefahren</td>
        <td></td>
    </tr>
    <tr>
        <td>zumachen</td>
        <td></td>
    </tr>
    <tr>
        <td>pruefen</td>
        <td></td>
    </tr>
    <tr>
        <td>Sonderaufgaben</td>
        <td></td>
    </tr>
    <tr>
        <td>verpackt</td>
        <td></td>
    </tr>
    <tr>
        <td>vernaeht-verpackt</td>
        <td></td>
    </tr>
    <tr>
        <td>Paket</td>
        <td></td>
    </tr>
    <tr>
        <td>Packmasse</td>
        <td></td>
    </tr>
    <tr>
        <td>Dachser </td>
        <td></td>
    </tr>
    <tr>
        <td>Kommission </td>
        <td>Shell Schlosserstr. </td>
    </tr>
    <tr>
        <td>Bestellung-vom </td>
        <td>2/2/23</td>
    </tr></tbody>
</table>
<br /><hr /><br />
<h5 id="notes">Notizen zum Auftrag</h5>
<div className="card">
<div className="card-header alert alert-primary ">
  Erneuter Anruf Kunde
  </div>

  <div className="card-body">
   <button type="button" className="btn btn-primary">
    Bearbeiter  <span className="badge text-bg-secondary">DW</span>
  </button><br /><br />
    <p className="card-text">05.05.2023</p>
    <p>
      Kunde hat angerufen und nachgefragt
    </p>
  
  </div></div><br />

  <div className="card">
  <div className="card-header alert alert-primary ">
  Anruf Kunde
  </div>
  <div className="card-body ">
   <button type="button" className="btn btn-primary">
    Bearbeiter  <span className="badge text-bg-secondary">AA</span>
  </button><br /><br />

    <p className="card-text">05.05.2023</p>
    <p>
      Kunde hat angerufen und nachgefragt
    </p>
  
  </div>
</div><br />
<hr />


                </div>
                
                </div>
            </div>
            </div>
            <div className="modal fade modal-fullscreen" id="exampleModal2" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                <div className="modal-header alert alert-primary">
                    <h5 className="modal-title" id="exampleModalLabel">Infos</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <ul className="list-group">
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
   Die Daten kommen aus einem via Schnittstelle angebunden WordPress und sind aus Sicherheitsgründen völlig losgelöst vom Frontend. Die Daten werden auch zurückgespeichert, via Schnittstelle.
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
 Mitarbeiter aus der Produktion sehen eine reduzierte Oberfläche, die lediglich Auftragsupdates anbietet sowie die Notizfunktion. 
 Der Screen Office bietet zusätzlich die Möglichkeit, Aufträge aus Bestellungen zu erstellen und zuzuweisen, sowie Statusinfomationen für den Fortschritt jedes Aufrages jedes Mitarbeiters.
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
   Die Anwendung kann als HybridApp (PWA) ausgeliefert werden
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
   Die Daten werden zwischengespeichert und sind auch offline verfügbar, wenn kein Internet vorhanden ist (Statusanzeige). Allerdings kann dann nichts angelegt werden, zum Speichern wird Internet benötigt,
   da es sonst zu inkonsistenten FDstenbestänen kommt oder man mit hohem Aufeand an Synchronisationslösungen arbeiten müsste.
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
   Durch den WordPress-Einsatz ist der Schulungsaufwand extrem minimal bis 0
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
   Die Anwendung ist gut skalierbar und kann mit modernen Technologien (React, HTML 5, JavaScript/TypeScript, REST, SCSS) weiter entwickelt werden
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
   Die Anwendung kann von jedem Frontwend-Entwickler weiterentwickelt werden, da die Daten und die Logik im GitHub Repository liegen
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
   Die Anwendung bedient ein breites Spektrum an Endgeräten, da sie dank HTML 5, JavaScript/TypeScript und SCSS praktisch plattformunabhängig ist
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..."/>
    Die ganze Anwendung wird automatisch aus diesen Daten gespeist und die Single Source of Truth ist das Backend
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..."/>
    Die bearbeitenden Mitarbeiter werden auomatisch aus dem Backend übernommen und können sinvollerweise nicht bearbeitet werden.
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..."/>
    Wir haben Revisionen der Daten
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..."/>
    Bei Bedarf kann sogar das Backend getauscht werden
  </li>
</ul>
                </div>
                
                </div>
            </div>
            </div></>
   )

}


