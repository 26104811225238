
import './App.css';
import { Orderlist } from './order/List';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useEffect } from 'react';

function App() {


  return (
<>
<a type="button" className='d-none' id="openmodal" data-bs-toggle="modal" data-bs-target="#exampleModalInfo">Launch modal</a>
<div className="modal fade modal-fullscreen" id="exampleModalInfo" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Infos</h5>
                    
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">


                </div>
                </div></div></div>
                
    <div className="App">
     
      <Orderlist />
    </div></>
  );
}

export default App;
