import React, {useState, useEffect} from 'react'


export const Row = ({elements}) => {
    const [days, setDays] = useState(0);
    const convertMiliseconds = (miliseconds)  => {
        var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;
        
        total_seconds = parseInt(Math.floor(miliseconds / 1000));
        total_minutes = parseInt(Math.floor(total_seconds / 60));
        total_hours = parseInt(Math.floor(total_minutes / 60));
        days = parseInt(Math.floor(total_hours / 24));
      
        seconds = parseInt(total_seconds % 60);
        minutes = parseInt(total_minutes % 60);
        hours = parseInt(total_hours % 24);
        
              return days;
       
       }
      useEffect(() => {
        const datum = new Date(elements.Versand).getTime();
        const heute = new Date('May 17, 2023 00:00:00').getTime();
        setDays(convertMiliseconds(datum-heute));
        console.log( days)
        }, [])

      const handleClick = () => {
    
      }
      if(days > -2 && days <= 1) {
        return (
            <tr className="alert alert-danger" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleClick}>
                <td>{elements.Id}</td>
                <td>{elements.Versand}</td>
                <td>{elements.Kunde}</td>
                <td>{elements.Tankform}</td>
                <td>{elements.Volumen}</td>
                <td>{elements.orderdate}</td>
                <td>{elements.Einbaudatum}</td>
            </tr>
        )
      }
      if(days > 1 & days < 10) {
        return (
            <tr className="alert alert-warning" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleClick}>
               <td>{elements.Id}</td>
                <td>{elements.Versand}</td>
                <td>{elements.Kunde}</td>
                <td>{elements.Tankform}</td>
                <td>{elements.Volumen}</td>
                <td>{elements.orderdate}</td>
                <td>{elements.Einbaudatum}</td>
            </tr>
        )
      }
      if(days < 0) {
        return (
            <tr className="alert alert-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleClick}>
               <td>{elements.Id}</td>
                <td>{elements.Versand}</td>
                <td>{elements.Kunde}</td>
                <td>{elements.Tankform}</td>
                <td>{elements.Volumen}</td>
                <td>{elements.orderdate}</td>
                <td>{elements.Einbaudatum}</td>
            </tr>
        )
      }
    return (
        <tr className="alert alert-success" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleClick}>
            <td>{elements.Id}</td>
                <td>{elements.Versand}</td>
                <td>{elements.Kunde}</td>
                <td>{elements.Tankform}</td>
                <td>{elements.Volumen}</td>
                <td>{elements.orderdate}</td>
                <td>{elements.Einbaudatum}</td>
        </tr>
    )

}
