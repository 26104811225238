import React, {useState, useEffect} from 'react'


export const Modalbuero = () => {
  const [chosen, setChosen] = useState(false);
  const [val, setVal] = useState(0);
  
  const options = ['1. Mitarbeiter auswählen', 'BB', 'MM', 'AB'];

  const setValue = (e) => {
    setVal(e.target.value);
    setChosen(true);
  }

  const progress = <div className="prog">
  <br /><br /><h4 className='border border-info border-start-0 bg-info bg-opacity-10'>BearbeitungsForschritt</h4>
  
  <ul className="list-group">
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" defaultChecked type="checkbox" value="" id="firstCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="firstCheckboxStretched">Zuschnitt</label>
    </li>
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" defaultChecked  type="checkbox" value="" id="secondCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="secondCheckboxStretched">Hülle gefahren</label>
    </li>
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="thirdCheckboxStretched">Hülle zumachen</label>
    </li>
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="thirdCheckboxStretched">Hülle Prüfen </label>
    </li>
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="thirdCheckboxStretched">Sonderaufgaben Auftrag </label>
    </li>
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="thirdCheckboxStretched">Hülle verpackt</label>
    </li>
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="thirdCheckboxStretched">Vlies vernäht/Vlies gepackt</label>
    </li>
    <li className="list-group-item">
      <input className="htmlForm-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
      <label className="htmlForm-check-label stretched-link" htmlFor="thirdCheckboxStretched">Paket gepackt</label>
    </li>
  
  
  </ul>
  
  <br/><br /><h4 className='border border-info border-start-0 bg-info bg-opacity-10'>Notizenverlauf</h4>
  <div className="d-flex justify-content-start w-100">
  <div className="card me-4 text-bg-primary"> 
    <div className="card-header">
      Notiz vom 23.05.2023
    </div>
    <div className="card-body">
      <h5 className="card-title">Kunde ruft an</h5>
      <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
      <a href="#" className="btn btn-primary"><i className="bi bi-pencil-square"></i></a>
    </div>
  </div>
  
  <div className="card me-4 text-bg-primary">
    <div className="card-header">
      Notiz vom 23.05.2023
    </div>
    <div className="card-body">
      <h5 className="card-title">Kunde ruft an</h5>
      <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
      <a href="#" className="btn btn-primary"><i className="bi bi-pencil-square"></i></a>
    </div>
  </div>
  <div className="card text-bg-primary">
    <div className="card-header">
      Notiz vom 23.05.2023
    </div>
    <div className="card-body">
      <h5 className="card-title">Kunde ruft an</h5>
      <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
      <a href="#" className="btn btn-primary"><i className="bi bi-pencil-square"></i></a>
    </div>
  </div>
  </div></div>;


if(chosen) {
  return(
    <>
    <div className="modal fade modal-fullscreen" id="exampleModalBuero" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                <div className="modal-header alert alert-primary">
                    <h5 className="modal-title" id="exampleModalLabel">Büro - Auftragsverwaltung</h5>
                    <a className="ms-5" href="#">Bestellungen anschauen <i className="bi bi-search"></i></a>
               
                    <a className="ms-5" href="#">Auftrag anlegen <i className="bi bi-plus-square-fill"></i></a>

                    <a className="ms-5" href="#">Mitarbeiter auswählen <i className="bi bi-check-circle-fill"></i></a>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    
                <h4 className='border border-info border-start-0 bg-info bg-opacity-10'>Aufträge anschauen</h4>
                <div className='w-100 ' id="progress">
                <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={setValue}>
                  {options.map((option, index) => {
                    return <option value={index} key={index}>{option}</option>
                  })}
                  
                </select>

                <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                  <option value="0">Aufträge des Mitarbeiters</option>
                  <option value="1">1267 - Kunde Bröcking - Zylinder</option>
                  <option value="2">1283 - Kunde TG - Kugel</option>
                  <option value="3">1283 - Kunde TG - Zylinder</option>
                </select>

                </div>

                {progress}

                </div>
                
                </div>
            </div>
            </div>
            </>
   )
}

   return (
    <>
    <div className="modal fade modal-fullscreen" id="exampleModalBuero" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                <div className="modal-header alert alert-primary">
                    <h5 className="modal-title" id="exampleModalLabel">Büro - Auftragsverwaltung</h5>
                    <a className="ms-5" href="#">Bestellungen anschauen <i className="bi bi-search"></i></a>
               
                    <a className="ms-5" href="#">Auftrag anlegen <i className="bi bi-plus-square-fill"></i></a>
                    <a className="ms-5" href="#">Mitarbeiter auswählen <i className="bi bi-check-circle-fill"></i></a>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    
                <h4 className='border border-info border-start-0 bg-info bg-opacity-10'>Aufträge anschauen</h4>
                <div className='w-100 ' id="progress">
                <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={setValue}>
                  {options.map((option, index) => {
                    return <option value={index} key={index}>{option}</option>
                  })}
                  
                </select>

                <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                  <option value="0">Aufträge des Mitarbeiters</option>
                  <option value="1">1267 - Kunde Bröcking - Zylinder</option>
                  <option value="2">1283 - Kunde TG - Kugel</option>
                  <option value="3">1283 - Kunde TG - Zylinder</option>
                </select>

                </div>

            

                </div>
                
                </div>
            </div>
            </div>
            </>
   )

}


