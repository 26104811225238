import React, {useState, useEffect} from 'react'
import logo from '../logo.png'
export const Nav = ({filterElements}) => {
    const [filterValue, setFilterValue] = useState('');

    const handleChange = (e) => {
        setFilterValue(e.target.value);
        filterElements(e.target.value, 'Input');
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                <a className="navbar-brand" href="/"><img src={logo} /></a>
               
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                     <a className="nav-link active" aria-current="page" href="/">Order</a>
                    </li>
                    <li className="nav-item">
                     <a className="nav-link active" aria-current="page" href="/" data-bs-toggle="modal" data-bs-target="#exampleModalBuero">Büro</a>
                    </li>
                    <li className="nav-item">
                     <a className="nav-link active"  data-bs-toggle="modal" data-bs-target="#exampleModalProd" href="#" aria-current="page">Produktion</a>
                    </li>
                    <li><div className="vr"></div></li>
                   
                    
                       
                    
                </ul>
                <div className="badge bg-primary text-wrap" >Hallo Mitarbeiter XYZ</div>
                <a className="btn btn-primary" href="#"><i className="bi bi-box-arrow-right"></i></a>
                </div>
            </div>
            </nav>
    )
}