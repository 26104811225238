import React, {useState, useEffect} from 'react'
import { JsonToTable } from "react-json-to-table";
import data from "./api.json";
import { Table } from './table/Table';
import { Filter } from './table/Filter';
import { Nav } from './Nav';
import { Modaldetail } from './table/Modaldetail';
import { Modalproduktion } from './table/Modalproduktion';
import { Modalbuero } from './table/Modalbuero';
export const Orderlist = () => {
    const [order, setOrder] = useState('bottom');
    const [kunden, setKunden] = useState([]);
    const [produkte, setProdukte] = useState([]);
    const [mitarbeiter, setMitarbeiter] = useState(['Ralph Zimmermann', 'Martin Schmitt','Nadine Hofmann', 'Torsten Achen','Barbara Faust', 'Ralph Klug']);
    const [orders, setOrders] = useState(data.data);
    const [filterValue, setFilterValue] = useState('');
    const compareDates = () => {

    }
    const handleChange = (e) => {
        setFilterValue(e.target.value);
        handleFilterChange(e.target.value, 'Input');
    }
    useEffect(() => {
        let beworders =orders.sort(function(a,b){
            //Turn your strings into dates, and then subtract them
            //to get a value that is either negative, positive, or zero.
            return new Date(a.Versand).getTime() - new Date(b.Versand).getTime();
          });
          setOrders(beworders)
    }, [orders])

    useEffect(() => {
        let kundenmap = data.data.map( (order, index) => {
            
            return order.Kunde
        });
        let unique = [...new Set(kundenmap)];
        setKunden(unique)
       
    }, [orders])

    useEffect(() => {
        let produktmap = data.data.map( (order, index) => {
            
            return order.Tankform
        });
        let unique = [...new Set(produktmap)];
        setProdukte(unique)
       
    }, [orders])
    
    const handleOrderChange = (direct) => {
        let new_order;
        setOrder(direct);
        if(order === "top") {

        
        new_order=orders.sort(function(a,b){
            //Turn your strings into dates, and then subtract them
            //to get a value that is either negative, positive, or zero.
            return new Date(a.Versand).getTime() - new Date(b.Versand).getTime();
          });
       
          setOrders(new_order)
        } else {
            new_order=orders.sort(function(a,b){
                //Turn your strings into dates, and then subtract them
                //to get a value that is either negative, positive, or zero.
                console.log(new Date(b.Versand) - new Date(a.Versand))
                return new Date(b.Versand) - new Date(a.Versand);
              });
            
              setOrders(new_order);
        }

        
          
    }
    const handleFilterChange = (val, type) => {
       let filtered = [];
       if(type === "Kunden") {
            filtered = data.data.filter( (order, index) => {
                return order.Kunde == val  || val === "all"
            });
       }
       if(type === "Mitarbeiter") {
        filtered = data.data.filter( (order, index) => {
            return val === "all"
        });
   }
       if(type === "Tankart") {
        filtered = data.data.filter( (order, index) => {
            if(val == "all") {return true}
            return order.Tankform == val  
        });
        }
        if(type === "Input") {
            
            
            if(val === "") {
                filtered = data.data
            } else {
                const regex = new RegExp(val.toLocaleLowerCase()+'*');
            filtered = data.data.filter( (order, index) => {
                
                return regex.test(order.Tankform.toLocaleLowerCase()) ||regex.test(order.Kunde.toLocaleLowerCase())
            });
        }
            } 
       setOrders(filtered)
    }

    return (
        <>
        <Modaldetail />
        <Modalproduktion />
        <Modalbuero />
        <div className="container p-4">
            <Nav filterElements={handleFilterChange}/>
            <p className='py-4'>
                <span className="badge bg-success">Anwendung ist online</span>
            </p>
            <div className='w-auto d-flex justify-content-between' id='filter'>
                <Filter filterElements={handleFilterChange} filter={kunden} title="Kunden"/>
                 <Filter filterElements={handleFilterChange} filter={produkte} title="Tankart"/>
                 <Filter filterElements={handleFilterChange} filter={mitarbeiter} title="Mitarbeiter"/>
                 <div>
                    <h6>Filtern</h6>
                    <input className="form-control form-control-md" type="search" placeholder="Filtern" aria-label="Search" value={filterValue} onChange={handleChange}/>
                    </div>
                <div className='text-center'> <h6>&nbsp;</h6>
                 <button className="btn-close" onClick={() => setOrders(data.data)}></button>
                 </div>
            </div>
        </div>
        <div className="container p-4">
            <Table orders={orders} handleOrderChange={handleOrderChange}/>
    
      </div>
      </>
    )

}
