import React, {useState, useEffect} from 'react'


export const Filter = ({filterElements, filter, title}) => {
    
    const [filterValue, setFilterValue] = useState('');

    const handleChange = (e) => {
        setFilterValue(e.target.value)
        filterElements(e.target.value, title)
    }

    return (
        <div>
        <h6>{title}</h6>
       
        <select className="form-select form-select-md mb-3" onChange={handleChange} value={filterValue}>
            <option value="all">Alle</option>
           {
           filter.map( (element, index) => {
            return filterValue===element ? <option key={index} selected value={element}>{element}</option> : <option key={index}  value={element}>{element}</option>
           })
        }
      </select>

      </div>
    )

}


