import React, {useState, useEffect} from 'react'
import { Row } from './Row'

export const Table = ({orders,handleOrderChange}) => {
    
    const [direct, setDir] = useState('bottom');

    const handleChange = (e) => {   
        let direction = direct;
        if(direction === 'bottom') {
            direction = 'top';
        } else {    
            direction = 'bottom';
        }
        setDir(direction);
        handleOrderChange(direction);
    }

    return (
        <>
        <div className="alert alert-secondary">
           <b>Die ist die Übersicht der Aufträge. Für die vollständige Ansicht bitte auf den Auftrag klicken.</b>
</div>        <table className="table ">
            <thead className="table-dark">
                <tr>
                <th scope="col">Auftrag</th>
                <th scope="col">Versanddatum <a href="#" className={direct} onClick={handleChange} id="filterDate"> <i className="bi bi-filter"></i> </a></th>
                <th scope="col">Kunde</th>
                <th scope="col">Tankart</th>
                <th scope="col">Tankvolumen</th>
                <th scope="col">Bestellt</th>
                <th scope="col">Einbau am</th>
                </tr>
            </thead>
        <tbody>
            {
               
            orders.map( (order, index) => {
               
               return <Row key={order.Id} elements={order} />
             })
          
            }            
        </tbody>
</table></>
    )

}
