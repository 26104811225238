import React, {useState, useEffect} from 'react'


export const Modalproduktion = () => {
   return (
    <>
    <div className="modal fade modal-fullscreen" id="exampleModalProd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                <div className="modal-header alert alert-primary">
                    <h5 className="modal-title" id="exampleModalLabel">Auftragsbearbeitung <span className="badge bg-secondary">Bearbeiter Michael Mustermann</span></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    
<h4 className='border border-info border-start-0 bg-info bg-opacity-10'>Deine Aufträge</h4>
<div className='w-100 ' id="progress">
<select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
  <option value="0">Auftrag auswählen</option>
  <option value="1">1267 - Kunde Bröcking - Zylinder</option>
  <option value="2">1283 - Kunde TG - Kugel</option>
  <option value="3">1283 - Kunde TG - Zylinder</option>
</select>

</div>
<br /><br /><h4 className='border border-info border-start-0 bg-info bg-opacity-10'>Bearbeitungsforschritt</h4>

<ul className="list-group">
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="firstCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="firstCheckboxStretched">Zuschnitt</label>
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="secondCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="secondCheckboxStretched">Hülle gefahren</label>
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="thirdCheckboxStretched">Hülle zumachen</label>
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="thirdCheckboxStretched">Hülle Prüfen </label>
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="thirdCheckboxStretched">Sonderaufgaben Auftrag </label>
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="thirdCheckboxStretched">Hülle verpackt</label>
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="thirdCheckboxStretched">Vlies vernäht/Vlies gepackt</label>
  </li>
  <li className="list-group-item">
    <input className="form-check-input me-1" type="checkbox" value="" id="thirdCheckboxStretched" />
    <label className="form-check-label stretched-link" htmlFor="thirdCheckboxStretched">Paket gepackt</label>
  </li>


</ul>
<br /><br /><h4 className='border border-info border-start-0 bg-info bg-opacity-10'>Notizen</h4>
<div className="form-floating">
  <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: '100px'}}></textarea>
  <label htmlFor="floatingTextarea2">Neue Notiz</label>
</div>
<br/><br /><h4 className='border border-info border-start-0 bg-info bg-opacity-10'>Notizenverlauf</h4>
<div className="d-flex justify-content-start w-100">
<div className="card me-4 text-bg-info"> 
  <div className="card-header">
    Notiz vom 23.05.2023
  </div>
  <div className="card-body">
    <h5 className="card-title">Kunde ruft an</h5>
    <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
    <a href="#" className="btn btn-primary"><i className="bi bi-pencil-square"></i></a>
  </div>
</div>

<div className="card me-4 text-bg-info">
  <div className="card-header">
    Notiz vom 23.05.2023
  </div>
  <div className="card-body">
    <h5 className="card-title">Kunde ruft an</h5>
    <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
    <a href="#" className="btn btn-primary"><i className="bi bi-pencil-square"></i></a>
  </div>
</div>
<div className="card text-bg-info">
  <div className="card-header">
    Notiz vom 23.05.2023
  </div>
  <div className="card-body">
    <h5 className="card-title">Kunde ruft an</h5>
    <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
    <a href="#" className="btn btn-primary"><i className="bi bi-pencil-square"></i></a>
  </div>
</div>
</div>

                </div>
                
                </div>
            </div>
            </div>
            </>
   )

}


